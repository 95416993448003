import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import SEO from "../helpers/seo"
import BgImg from "../helpers/bgimg"
import useWindowDimensions from "../helpers/utils"
import "swiper/css/swiper.css"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline} from "react-gsap"
import Swiper from 'react-id-swiper'
import Img from 'gatsby-image'

export default function Home({ data }) {
  const {
    seoTitle,
    seoKeywords,
    bottomTagline,
    headline,
    bottomSubTitle,
    featuredCaseStudy,
    bottomCtaCopy,
  } = data.contentfulHomePage
  const [hover, setHover] = useState(false)
  const [setSelectedBgColor] = useState(null)
  const { width } = useWindowDimensions()

  const params = {
    direction: "horizontal",
    slidesPerView: 1,
    speed: 1000,
    autoHeight: true,
    // freeMode: true,
    mousewheel: {
      // sensitivity: 50,
      releaseOnEdges: true,
    },
    pagination: false,
  }

  const mouseOut = () => {
    setHover(false);
	};

  const createMarkup = content => {
    return { __html: content }
  }

  return (
    <React.Fragment>
      <Helmet>
        <body
          className={hover ? "home-page header-transparent" : "home-page"}
        />
      </Helmet>
      <SEO title={seoTitle} keywords={seoKeywords} />
      <div
                  id="bannerCopy"
                  className="content-wrapper"
                  dangerouslySetInnerHTML={createMarkup(headline.headline)}
                />
			{/* <div className="bg-color" style={ (hover) ? {backgroundColor: selectedBgColor, opacity: 0.9} : {opacity: 0} }></div> */}

      <Controller>
      <Scene
        triggerHook="onLeave"
        pin
        duration={5000}
      >
        {progress => {       
          return (
            <div className="desk-slider" style={{ height: "calc(100vh)", zIndex: "5" }}>
              <Timeline>
                {featuredCaseStudy.map((post, i) => (
                    <Tween
            totalProgress={progress}
            paused
                      key = {"Slides " + i} 
                      from={{x: (i  * 100) + "%"}}
                      to={{x: ((featuredCaseStudy.length - i - 1)  * -100) + "%"  }}                    
                    >{
                      <div style={{ position: "absolute", width: "100%" }}>
                      <div id={"Slide" + i} className="content-wrapper"  >
                        <div 
                          style={{
                            position: "relative",
                            top: "110px",
                            left: "0px",
                            width: "auto",
                            height: "calc(100vh - 100px)",   
                          }}
                        >
							<AniLink fade to={`/case-study/${post.slug}`}>
                              <Img fluid={post.heroImage.fluid} alt={post.heroImage.title} />
                              <div 
                                  className="project-title all-caps"
                                  style={
                                      width > 1100 && hover ? { color: post.linkFontColor } : null
                                  }
                              >
                                  {post.title}
                              </div>
                              <div
                                  
                                  className="link"
                                  style={
                                      width > 1100 && hover ? { color: post.linkFontColor } : null
                                  }
                              >
                                  View Case Study <span>→</span>
                              </div>
                          </AniLink>
                        </div>
                      </div>
                    </div>                      
                      }</Tween>
                ))}
              </Timeline>
            </div>
          )
        }}
      </Scene>
    </Controller>

			<div className="mobile-slider" >
      <Swiper {...params}>
				{
					featuredCaseStudy.map( (post) => (
						<div className="outer-wrapper mobile-slider" key={post.id}>
							<AniLink fade to={`/case-study/${post.slug}`} onMouseOver={ () => {
								setHover(true);
								setSelectedBgColor(post.backgroundColor);
							} } onMouseOut={ mouseOut }>
								<Img fluid={post.heroImage.fluid} alt={post.heroImage.title} />
								<div className="project-title all-caps" style={ (width > 1100 && hover) ? {color: post.linkFontColor} : null }>{post.title}</div>
								<div className="link" style={ (width > 1100 && hover) ? {color: post.linkFontColor} : null }>View Case Study <span>→</span></div>
							</AniLink>
						</div>
					))
				}
			</Swiper>
      </div>

      <div className="content-wrapper">
        <section className="services">
          <BgImg className="office-image" filename="office-environment.jpg" />
          <div className="content-right">
            <div
              className="floating-content"
              dangerouslySetInnerHTML={createMarkup(
                bottomTagline.content[0].content[0].value
              )}
            />
            <div className="project-title all-caps">{bottomSubTitle}</div>

            <AniLink fade to="/services">
              {bottomCtaCopy}
              <span>→</span>
            </AniLink>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export const query = graphql`
  query HomeQuery {
    contentfulHomePage {
      seoTitle
      seoKeywords
      headline {
        headline
      }
      bottomCtaCopy
      bottomSubTitle
      bottomTagline {
        content {
          content {
            value
          }
        }
      }
      featuredCaseStudy {
        ... on ContentfulCaseStudyLongForm {
          heroImage {
            fluid(maxWidth: 1060) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          id
          title
          slug
          linkFontColor
          backgroundColor
        }
        ... on ContentfulCaseStudyShortForm {
          heroImage {
            fluid(maxWidth: 1060) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          id
          title
          slug
          linkFontColor
          backgroundColor
        }
      }
    }
  }
`
